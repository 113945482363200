/* Tailwind directives */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --black: #000000;
  --dark-blue: #0f172a;
  --blue: #1e3a8a;
  --silver: #e2e8f0;
  --accent-blue: #3b82f6;
  --text-primary: #f8fafc;
  --text-secondary: #94a3b8;
}

body {
  color: var(--text-primary);
  background: linear-gradient(135deg, var(--black) 0%, var(--dark-blue) 50%, var(--blue) 100%);
  font-feature-settings: "rlig" 1, "calt" 1;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.login-button {
  background: linear-gradient(#272730, #2d4052);
  cursor: pointer;
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(#2d4052, #272730);
  position: fixed;
  bottom: 2;
  left: -4;
  margin-left: 1rem;
}

.login-button:hover {
  background: linear-gradient(#230272, #000);
  border: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(#2d4052, #272730);
  opacity: 0.65;
  will-change: background, opacity;
}

.hexagon-grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.hexagon {
  opacity: 0.15;
  animation: pulse 4s ease-in-out infinite;
}

.hexagon:nth-child(3n) {
  animation-delay: -1s;
}

.hexagon:nth-child(5n) {
  animation-delay: -2s;
}

.hexagon:nth-child(7n) {
  animation-delay: -3s;
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.card:hover {
  border-color: var(--accent-blue);
  box-shadow: 0 0 30px rgba(59, 130, 246, 0.3);
  transform: translateY(-5px);
}

.text-balance {
  text-wrap: balance;
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in;
}

.animate-slide-up {
  animation: slideUp 0.5s ease-out;
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
